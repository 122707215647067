import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../utils/contexts/AppContext'

function RequireAdmin() {
    // Global
    const { employee } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!employee) return;

        if (!employee.is_admin) {
            navigate("/");
        }

    }, [employee])

    return <Outlet />
}

export default RequireAdmin