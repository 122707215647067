import { Box, Button, Stack, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { signIn, signUp } from '../../shared/utils/helpers/authHelpers';

const centerStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    webkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    height: "75%",
    width: "75%"
}

function LoginPage() {

    // Login Form
    const loginForm = useForm({
        initialValues: {
            email: "",
            password: ""
        }
    });

    const handleSubmit = async (values) => {
        await signIn(values);
    }

    const dev_signUp = async () => {
        // await signUp({ email: "stephanie@elitesleepprofessionals.com", password: "Muffin23!" }, { company_id: "e08e7aa7-dc04-4253-a4ee-4c2c9ab05e9f", first_name: "Stephanie", last_name: "O'Connor" });
        // await signUp({ email: "megan@elitesleepprofessionals.com", password: "Gummybear12" }, { company_id: "e08e7aa7-dc04-4253-a4ee-4c2c9ab05e9f", first_name: "Megan", last_name: "Taylor Bates" });
        // await signUp({ email: "cill@elitesleepprofessionals.com", password: "14bubbles" }, { company_id: "e08e7aa7-dc04-4253-a4ee-4c2c9ab05e9f", first_name: "Hutis", last_name: "Williams" });
        // await signUp({ email: "tgarrett@elitesleepprofessionals.com", password: "Feep24" }, { company_id: "e08e7aa7-dc04-4253-a4ee-4c2c9ab05e9f", first_name: "Tina", last_name: "Garrett" });
        // await signUp({ email: "mjohnson@elitesleepprofessionals.com", password: "Feep24" }, { company_id: "e08e7aa7-dc04-4253-a4ee-4c2c9ab05e9f", first_name: "Melody", last_name: "Johnson" });
    }

    return (
        <Box sx={centerStyle}>
            <Stack justify="center" align="center" p="md">
                <Title>Elite Sleep</Title>

                <form onSubmit={loginForm.onSubmit(handleSubmit)} style={{ width: "100%" }}>
                    <Stack spacing="md">
                        <TextInput 
                            label="Email"
                            type="email"
                            withAsterisk
                            {...loginForm.getInputProps("email")}
                        />
                        <TextInput 
                            label="Password"
                            type="password"
                            withAsterisk
                            {...loginForm.getInputProps("password")}
                        />
                        <Button color="green" type="submit">Sign In</Button>
                    </Stack>
                </form>

                {/* <Button type="button" onClick={dev_signUp}>Dev Sign Up</Button> */}
            </Stack>
        </Box>
    )
}

export default LoginPage