import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { supabase } from "../../lib/supabaseClient";
import { fetchRecentTimeClocks } from "../helpers/timeHelpers";
import { useAppContext } from "./AppContext";

export const useTimeContext = () => {
    return useContext(TimeContext);
}

const TimeContext = createContext();

export const TimeProvider = ({ children }) => {
    // Global
    const { employee } = useAppContext();

    // States
    const [timeClocks, setTimeClocks] = useState([]);

    // Runs on page load
    useEffect(() => {
        if (!employee) return;
        
        // Fetch the most recent time clocks
        fetchRecentTimeClocks(employee.id, setTimeClocks);
    }, [employee])

    // Initiate clock in
    const clockIn = async () => {
        try {
            await supabase.from("time_clock").insert({ employee_id: employee.id, company_id: employee.company_id });

            await fetchRecentTimeClocks(employee.id, setTimeClocks);
        } catch (err) {
            console.log("clockin", err);
        }
    }

    // Initiate clock out
    const clockOut = async (currentId) => {
        try {
            await supabase.from("time_clock")
                .update({ clock_out: new Date(), is_clocked_in: false })
                .eq("id", currentId);

            await fetchRecentTimeClocks(employee.id, setTimeClocks);
        } catch (err) {
            console.log("clockout", err);
        }
    }

    const value = useMemo(() => ({
        timeClocks: timeClocks,
        currentTimeClock: timeClocks.find(x => x.is_clocked_in),
        clockIn: clockIn,
        clockOut: clockOut
    }), [timeClocks])

    return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
}