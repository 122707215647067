import React from 'react'
import { Outlet } from 'react-router-dom'
import LoginPage from '../../../Auth/LoginPage';
import { useAppContext } from '../../utils/contexts/AppContext'

function RequireAuth() {
    // Global
    const { session } = useAppContext();

    if (!session) {
        return <LoginPage />
    }

    return <Outlet />
}

export default RequireAuth