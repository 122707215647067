import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { supabase } from "../../lib/supabaseClient";
import { LOCAL_STORAGE_REFS } from "../constants";
import { fetchUserProfile } from "../helpers/authHelpers";
import useLocalStorage from "../hooks/useLocalStorage";

export const useAppContext = () => {
    return useContext(AppContext);
}

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    // Global

    // States
    const [session, setSession] = useLocalStorage(LOCAL_STORAGE_REFS.Session, null);
    const [employee, setEmployee] = useState(null);

    // Runs on application load
    useEffect(() => {

        // Try to retrieve the current session if exists
        supabase.auth.getSession().then(({ data: session }) => {
            setSession(session?.session);
        });

        // Start listening to auth changes
        const { data: listener } = supabase.auth.onAuthStateChange((event, session) => {
            if (event === "SIGNED_IN") {
                setSession(session);
            }

            if (event === "SIGNED_OUT") {
                setSession(null);
            }
        });

        return () => {
            listener.subscription.unsubscribe();
        }

    }, [])

    // Runs when a session is established
    useEffect(() => {
        if (session) {
            fetchUserProfile(session.user.id, setEmployee);
        }
    }, [session])

    const value = useMemo(() => ({
        session: session,
        employee: employee
    }), [session, employee])

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}