import { supabase } from "../../lib/supabaseClient";

/**
 * Signs in the user
 * @param {object} credentials { email, password } 
 */
export const signIn = async (credentials) => {
    try {
        await supabase.auth.signInWithPassword(credentials);
    } catch (err) {
        console.log("signin", err);
    }
}

/**
 * Signs out the user
 */
export const signOut = async () => {
    try {
        await supabase.auth.signOut();
    } catch (err) {
        console.log("signout", err);
    }
}

/**
 * Signs up a new user
 * @param {object} credentials { email, password }
 * @param {object} metadata { companyId, first_name, last_name }
 */
export const signUp = async (credentials, metadata) => {
    try {
        await supabase.auth.signUp({...credentials, options: { data: metadata }});
    } catch (err) {
        console.log("signup", err);
    }
}

/**
 * Fetch the logged in user's profile
 */
export const fetchUserProfile = async (employeeId, setState) => {
    try {
        const { data } = await supabase.from("employee").select("*").eq("id", employeeId);
        if (setState) setState(data.length > 0 ? data[0] : null);
        return data.length > 0 ? data[0] : null;
    } catch (err) {
        console.log("fetchProfile", err);
    }
}