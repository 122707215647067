import { MantineProvider } from "@mantine/core";
import React from "react";
import { Outlet } from "react-router-dom";
import { AppProvider } from "./shared/utils/contexts/AppContext";

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <AppProvider>
        <Outlet />
      </AppProvider>
    </MantineProvider>
  );
}

export default App;
