import { Box, LoadingOverlay } from '@mantine/core';
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import RequireAuth from './shared/components/Auth/RequireAuth';

// Un-Authenticated Routes
import LoginPage from './Auth/LoginPage';
import { TimeProvider } from './shared/utils/contexts/TimeContext';
import RequireAdmin from './shared/components/Admin/RequireAdmin';

// Main Application Routes
const MainLayout = lazy(() => import("./Main"));
const Dashboard = lazy(() => import("./Main/Dashboard"));

const TimeWrapper = lazy(() => import("./Main/TimeClock"));
const TimeEmployee = lazy(() => import("./Main/TimeClock/Employee"));

const Admin = lazy(() => import("./Main/Admin"));
const AdminTimeReports = lazy(() => import("./Main/Admin/TimeReports"));

const FallbackContainer = () => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
            <LoadingOverlay visible={true} overlayBlur={2} />
        </Box>
    );
}

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>

                    {/* Un-Authenticated Routes */}
                    <Route path="login" element={<LoginPage />} />

                    
                    {/* Main Application Routes */}
                    <Route element={<RequireAuth />}>

                        <Route
                            path="/"
                            element={
                                <Suspense fallback={<FallbackContainer />}>
                                    <MainLayout />
                                </Suspense>
                            }
                        >

                            {/* Dashboard Page */}
                            <Route 
                                index
                                element={
                                    <Suspense fallback={<FallbackContainer />}>
                                        <Dashboard />
                                    </Suspense>
                                }
                            />

                            {/* Time Clock Section */}
                            <Route 
                                path="time"
                                element={
                                    <Suspense fallback={<FallbackContainer />}>
                                            <TimeWrapper />
                                    </Suspense>
                                }
                            >
                                
                                {/* Employee Page */}
                                <Route 
                                    index
                                    element={
                                        <Suspense fallback={<FallbackContainer />}>
                                            <TimeEmployee />
                                        </Suspense>
                                    }
                                />

                            </Route>

                            {/* Admin-Only Section */}
                            <Route element={<RequireAdmin />}>

                                <Route 
                                    path="admin"
                                    element={
                                        <Suspense fallback={<FallbackContainer />}>
                                            <Admin />
                                        </Suspense>
                                    }
                                />

                                <Route 
                                    path="admin/time"
                                    element={
                                        <Suspense fallback={<FallbackContainer />}>
                                            <AdminTimeReports />
                                        </Suspense>
                                    }
                                />

                            </Route>
                            

                        </Route>

                    </Route>

                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes