import { supabase } from "../../lib/supabaseClient";

/**
 * Fetch most recent time clocks
 */
export const fetchRecentTimeClocks = async (employeeId, setState) => {
    try {
        const { data } = await supabase.from("time_clock")
            .select("*")
            .order("clock_in", { ascending: true })
            .limit(30);
        if (setState) setState(data);
        return data;
    } catch (err) {
        console.log("fetchRecentTimeClocks", err);
    }
}

/**
 * Clock out a user
 */
export const clockOut = async (clockId) => {
    
}